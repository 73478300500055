import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
	{
        path: '/login',
        name: 'login',
        component: () => import('./views/Login.vue')
    },
	{
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('./components/Dashboard.vue'),
		meta: {
			requireAuth: true
		},
    },
	{
        path: '/logs',
        name: 'logs',
        component: () => import('./views/Logs.vue'),
		meta: {
			requireAuth: true
		},
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {	
		path: '/register',
		name: 'register',
		meta: {
			requireAuth: true
		},
        children: [
            {
				path: 'user',
				name: 'Register user',
				component: () => import("./views/Register/User"),
				meta: {
					requireAuth: true
				},
			},
            {
				path: 'region',
				name: 'Register region',
				component: () => import("./views/Register/Region"),
				meta: {
					requireAuth: true
				},
			},
            {
				path: 'session',
				name: 'Register session',
				component: () => import("./views/Register/Session"),
				meta: {
					requireAuth: true
				},
			},
            {
				path: 'point',
				name: 'Register point',
				component: () => import("./views/Register/Point"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'quoted',
				name: 'Register quoted',
				component: () => import("./views/Register/Quoted"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'category',
				name: 'Register category',
				component: () => import("./views/Register/ExpenseCategory"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'link_region',
				name: 'Link Region',
				component: () => import("./views/Register/LinkRegion"),
				meta: {
					requireAuth: true
				},
			},
        ]
    },
	{	
		path: '/financial',
		name: 'financial',
		meta: {
			requireAuth: true
		},
        children: [
            {
				path: 'income',
				name: 'Financial income',
				component: () => import("./views/Financial/Income"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'retreat',
				name: 'Financial retreat',
				component: () => import("./views/Financial/Retreat"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'point_expense',
				name: 'Financial point expense',
				component: () => import("./views/Financial/PointExpense"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'session_expense',
				name: 'Financial session expense',
				component: () => import("./views/Financial/SessionExpense"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'expense_schedule',
				name: 'Financial fixed expense',
				component: () => import("./views/Financial/ExpenseSchedule"),
				meta: {
					requireAuth: true
				},
			},
        ]
    },
    {	
		path: '/report',
		name: 'report',
		meta: {
			requireAuth: true
		},
        children: [
            {
				path: 'extraction',
				name: 'Report extraction',
				component: () => import("./views/Report/Extraction"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'point_extraction',
				name: 'Report point extraction',
				component: () => import("./views/Report/PointExtraction"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'region',
				name: 'Report region',
				component: () => import("./views/Report/Region"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'session',
				name: 'Report session',
				component: () => import("./views/Report/Session"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'point',
				name: 'Report point',
				component: () => import("./views/Report/Point"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'operator',
				name: 'Report operator',
				component: () => import("./views/Report/Operator"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'daily',
				name: 'Report daily',
				component: () => import("./views/Report/Daily"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'late_animals',
				name: 'Report late animals',
				component: () => import("./views/Report/LateAnimals.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'cancelations',
				name: 'Report cancelations',
				component: () => import("./views/Report/Cancelations.vue"),
				meta: {
					requireAuth: true
				},
			},
		
        ]
    },
	{	
		path: '/statistics',
		name: 'statistics',
		meta: {
			requireAuth: true
		},
        children: [
			{
				path: 'modality',
				name: 'Statistics modality',
				component: () => import("./views/Statistics/Modality"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'product',
				name: 'Statistics product',
				component: () => import("./views/Statistics/Product"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'region',
				name: 'Statistics region',
				component: () => import("./views/Statistics/Region"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'session',
				name: 'Statistics session',
				component: () => import("./views/Statistics/Session"),
				meta: {
					requireAuth: true
				},
			},
            {
				path: 'point',
				name: 'Statistics point',
				component: () => import("./views/Statistics/Point"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'extraction',
				name: 'Statistics extraction',
				component: () => import("./views/Statistics/Extraction"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'daily_extraction',
				name: 'Statistics daily extraction',
				component: () => import("./views/Statistics/DailyExtraction"),
				meta: {
					requireAuth: true
				},
			},
        ]
    },
	{	
		path: '/entry',
		name: 'entry',
		meta: {
			requireAuth: true
		},
        children: [
			{
				path: 'result',
				name: 'Statistics result',
				component: () => import("./views/Entry/Result"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'claim',
				name: 'Statistics claim',
				component: () => import("./views/Entry/Claim"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'messages',
				name: 'Messages',
				component: () => import("./views/Entry/Messages"),
				meta: {
					requireAuth: true
				},
			},
        ]
    },
	{	
		path: '/bolao',
		name: 'bolao',
		meta: {
			requireAuth: true
		},
        children: [
			{
				path: 'round_config',
				name: 'Bolao round_config',
				component: () => import("./views/Bolao/RoundConfig"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'round_details',
				name: 'Bolao round_details',
				component: () => import("./views/Bolao/RoundDetails"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'round_groups',
				name: 'Bolao round_groups',
				component: () => import("./views/Bolao/RoundGroupsDetails"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'new_round_groups',
				name: 'Bolao new_round_groups',
				component: () => import("./views/Bolao/NewRoundGroupsDetails"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'point',
				name: 'Bolao point',
				component: () => import("./views/Bolao/Point"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'map',
				name: 'Bolao map',
				component: () => import("./views/Bolao/Map"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'income',
				name: 'Bolao income',
				component: () => import("./views/Bolao/Income"),
				meta: {
					requireAuth: true
				},
			},
        ]
    },
	{	
		path: '/tickets',
		name: 'tickets',
		meta: {
			requireAuth: true
		},
        children: [
			{
				path: 'all',
				name: 'Tickets all',
				component: () => import("./views/Tickets/AllTickets"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'authenticated',
				name: 'Tickets authenticated',
				component: () => import("./views/Tickets/AuthenticatedTickets"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'expired',
				name: 'Tickets expired',
				component: () => import("./views/Tickets/ExpiredTickets.vue"),
				meta: {
					requireAuth: true
				},
			}
        ]
    },
	{	
		path: '/discharges',
		name: 'discharges',
		meta: {
			requireAuth: true
		},
        children: [
			{
				path: 'send',
				name: 'Discharges send',
				component: () => import("./views/Discharge/SendDischarge.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'manual',
				name: 'Discharges manual',
				component: () => import("./views/Discharge/ManualDischarge.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'summary',
				name: 'Discharges summary',
				component: () => import("./views/Discharge/Summary.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'tickets',
				name: 'Discharges tickets',
				component: () => import("./views/Discharge/Tickets.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'date_summary',
				name: 'Discharges Date Summary',
				component: () => import("./views/Discharge/DateSummary.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'extraction_tips',
				name: 'Discharges Extraction Tips',
				component: () => import("./views/Discharge/ExtractionTips.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'overview',
				name: 'Discharges Overview',
				component: () => import("./views/Discharge/Overview.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'limits',
				name: 'Discharges LimitsConfig',
				component: () => import("./views/Discharge/LimitsConfig.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'targets',
				name: 'Discharges Targets',
				component: () => import("./views/Discharge/DischargeTarget.vue"),
				meta: {
					requireAuth: true
				},
			},
        ]
    },
	{	
		path: '/config',
		name: 'config',
		meta: {
			requireAuth: true
		},
        children: [
			{
				path: 'payment_guide',
				name: 'Config Payment Guide',
				component: () => import("./views/Config/PaymentGuide.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'modality',
				name: 'Config Modality',
				component: () => import("./views/Config/Modality.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'product',
				name: 'Config Product',
				component: () => import("./views/Config/Products.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'lottery_schedule',
				name: 'Config LotterySchedule',
				component: () => import("./views/Config/LotterySchedule.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'region',
				name: 'Config Region',
				component: () => import("./views/Config/Region.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'extraction',
				name: 'Config extraction',
				component: () => import("./views/Config/Extraction.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'change_password',
				name: 'Change Password',
				component: () => import("./views/Config/ChangePassword.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'menu',
				name: 'Config menu',
				component: () => import("./views/Config/MenuConfiguration.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'ticket_attachment',
				name: 'Config Attachment',
				component: () => import("./views/Config/TicketAttachment.vue"),
				meta: {
					requireAuth: true
				},
			}
        ]
    },
	{	
		path: '/affiliates',
		name: 'affiliates',
		meta: {
			requireAuth: true
		},
        children: [
			{
				path: 'users',
				name: 'Affiliate Users',
				component: () => import("./views/Affiliate/Affiliate.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'dashboard',
				name: 'Affiliate Dashboard',
				component: () => import("./views/Affiliate/AffiliateDashboard.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'comissions',
				name: 'Affiliate Comissions',
				component: () => import("./views/Affiliate/AffiliateComissions.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'closures',
				name: 'Affiliate Closure',
				component: () => import("./views/Affiliate/AffiliateClosures.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'entries',
				name: 'Affiliate Entry',
				component: () => import("./views/Affiliate/AffiliateEntries.vue"),
				meta: {
					requireAuth: true
				},
			},
        ]
    },
	{	
		path: '/online',
		name: 'online',
		meta: {
			requireAuth: true
		},
        children: [
			{
				path: 'user',
				name: 'Online User',
				component: () => import("./views/Online/User.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'tickets',
				name: 'Online tickets',
				component: () => import("./views/Online/Tickets.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'transactions',
				name: 'Online transactions',
				component: () => import("./views/Online/Transactions.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'affiliate_comissions',
				name: 'Online affiliate_comissions',
				component: () => import("./views/Online/AffilitateComissions.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'marketing',
				name: 'Online marketing',
				component: () => import("./views/Online/Marketing.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'reports',
				name: 'Online reports',
				component: () => import("./views/Online/Report.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'expenses',
				name: 'Online expenses',
				component: () => import("./views/Online/Expense.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'bonus',
				name: 'Online bonus',
				component: () => import("./views/Online/Bonus.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'solicitations',
				name: 'Online solicitations',
				component: () => import("./views/Online/Solicitations"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'banner',
				name: 'Online banner',
				component: () => import("./views/Online/Banner.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'prize_log',
				name: 'Online prize_log',
				component: () => import("./views/Online/PrizeLog.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'dashboard',
				name: 'Online dashboard',
				component: () => import("./views/Online/Dashboard.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'article_categories',
				name: 'Online article_categories',
				component: () => import("./views/Online/ArticleCategories.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'articles',
				name: 'Online articles',
				component: () => import("./views/Online/Article.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'regulation',
				name: 'Online regulation',
				component: () => import("./views/Online/Regulation.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'config',
				name: 'Online config',
				component: () => import("./views/Online/Config.vue"),
				meta: {
					requireAuth: true
				},
			}
        ]
    },
	{	
		path: '/slots',
		name: 'slots',
		meta: {
			requireAuth: true
		},
        children: [
			{
				path: 'games',
				name: 'Slots games',
				component: () => import("./views/Slots/Games.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'collections',
				name: 'Slot collections',
				component: () => import("./views/Slots/Collections.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'campaigns',
				name: 'Slot campaigns',
				component: () => import("./views/Slots/Campaigns.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'report',
				name: 'Slot report',
				component: () => import("./views/Slots/Report.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'games_report',
				name: 'Game report',
				component: () => import("./views/Slots/GamesReport.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'providers_report',
				name: 'Provider report',
				component: () => import("./views/Slots/ProvidersReport.vue"),
				meta: {
					requireAuth: true
				},
			},
        ]
    },
	{	
		path: '/prize_draws',
		name: 'prize_draws',
		meta: {
			requireAuth: true
		},
        children: [
			{
				path: 'rifas',
				name: 'Rifas',
				component: () => import("./views/PrizeDraws/Rifa.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'tickets',
				name: 'Bilhetes rifa',
				component: () => import("./views/PrizeDraws/Ticket.vue"),
				meta: {
					requireAuth: true
				},
			},
			{
				path: 'report',
				name: 'Relatório rifa',
				component: () => import("./views/PrizeDraws/Report.vue"),
				meta: {
					requireAuth: true
				},
			},
        ]
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

function hasAccess(toPath, items) {

	for (let item of items) {
		if (item.to === toPath && item.selected) {
			return true;
		}

		if (item.items) {
			if (hasAccess(toPath, item.items)) {
				return true; 
			}
		}
	}
	return false; 
}
   


import store from "./store/store";

router.beforeEach((to, from, next) => {
	const isLogged = store.getters.getLogged
	const userPermissions = store.getters.getMenuItems; 

	if(to.path == '/' && !isLogged) {
		next('/login')
		return
	}

	if (to.path != '/' && isLogged && !hasAccess(to.path, userPermissions)) {
		next('/'); 
		return;
	}

	if (to.meta.requireAuth) {
				
		if (!isLogged) {
			next('/login');
		} else {
			next();
		}

	} else {
		next();
	}
});

export default router;