<template>
    <Toast/>

    <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)">
        <div class="layout-content-wrapper">
            <AppTopBar :layoutMode="layoutMode" :topbarTheme="topbarTheme" :menuActive="menuActive" :mobileMenuActive="staticMenuMobileActive" :sidebarActive="sidebarActive" :sidebarStatic="sidebarStatic" 
                :topbarUserMenuActive="topbarUserMenuActive" :topbarUserMenuClick="topbarUserMenuClick" :topbarItemClick="topbarItemClick" 
                :activeTopbarItem="activeTopbarItem" @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" 
                @menu-button-click="onMenuButtonClick" @toggle-menu="onToggleMenu" @topbar-usermenu-click="onTopbarUserMenuClick"
                @sidebar-mouse-leave="onSidebarMouseLeave" @sidebar-mouse-over="onSidebarMouseOver"
                @logout-click="onLogout"/>

            <div class="layout-main">
                <div class="layout-content">
                    <router-view/>
                </div>
                <AppFooter/>
            </div>
        </div>

        <AppConfig :layoutMode="layoutMode" :menuTheme="menuTheme" v-model:colorScheme="colorScheme" :topbarTheme="topbarTheme" v-model:configActive="configActive" @change-layout-mode="onChangeLayoutMode"
            @config-click="onConfigClick" @config-button-click="onConfigButtonClick" @change-color-scheme="changeColorScheme" @change-component-theme="changeComponentTheme" 
            @topbar-theme="onTopbarThemeChange" @menu-theme="onMenuThemeChange"></AppConfig>

        <div class="layout-mask modal-in"></div>
    </div>
</template>

<script>
import { DomHandler } from 'primevue/utils';
import EventBus from './event-bus';
import AppTopBar from "./AppTopbar";
import AppFooter from "./AppFooter";
import AppConfig from "./AppConfig";
import { io } from "socket.io-client";

export default {

    props: {
        topbarTheme: String,
        menuTheme: String
    },

    emits: ["change-color-scheme", "change-component-theme", "topbar-theme", "menu-theme"],

    data() {
        return {
            menuActive: false,
            layoutMode: "sidebar",
            colorScheme: null,
            sidebarActive: false,
            sidebarStatic: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            topbarItemClick: false,
            activeTopbarItem: null,
            userMenuClick: false,
            topbarUserMenuActive: false,
            topbarUserMenuClick: false,
            configActive: false,
            configClick: false
        };
    },

    mounted() {
        const socket = io('ws.controle.pro');

        socket.on('connect', () => {
            console.log('WebSocket Connected');
        });

        socket.on('online-warning', (warning) => {
            if(
                this.$store.getters.getShowNotifications 
                && this.$store.getters.getLogged
                && this.loggedUser().type == 'auxiliar'
            ) {
                this.$toast.add({severity:'warn', summary: 'Atenção!', detail: warning.description});
                
                var audio = new Audio(warning.audio_cdn);
                audio.play();
            }
        });

        socket.on('discharge-warning', (warning) => {
            if(this.$store.getters.getLogged && this.loggedUser().type == 'master') {
                this.$toast.add({severity:'warn', summary: 'Atenção!', detail: warning.description});
                
                var audio = new Audio(warning.audio_cdn);
                audio.play();
            }
        });

        this.colorScheme = this.$store.getters.getColorScheme;
    },

    computed: {
        containerClass() {
            return [
                "layout-wrapper",
                {
                    "layout-sidebar": this.layoutMode === "sidebar",
                    "layout-static": this.layoutMode === "sidebar" && this.sidebarStatic,
                    "layout-horizontal": this.layoutMode === "horizontal",
                    "layout-slim": this.layoutMode === "slim",
                    'layout-mobile-active': this.staticMenuMobileActive,
                    "p-input-filled": this.$primevue.config.inputStyle === "filled",
                    "p-ripple-disabled": !this.$primevue.config.ripple,
                },
                "layout-menu-" + this.menuTheme + ' layout-topbar-' + this.topbarTheme
            ];
        },
    },
    components: {
        AppTopBar,
        AppFooter,
        AppConfig,
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    methods: {
        onDocumentClick() {

            if (!this.topbarUserMenuClick && this.topbarUserMenuActive) {
                this.topbarUserMenuActive = false;
                this.topbarUserMenuClick = false;
            }

            if (!this.menuClick) {
                if (this.isSlim() || this.isHorizontal()) {
					EventBus.emit('reset-active-index');
					this.menuActive = false;
                }

                if (this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                this.unblockBodyScroll();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.configClick = false;
            this.userMenuClick = false;
            this.topbarUserMenuClick = false;
            this.menuClick = false;
        },


        onLogout() {
            this.logout()
        },

        onToggleMenu(event) {
            this.menuClick = true;
            this.sidebarStatic = !this.sidebarStatic;

            event.preventDefault();
        },

        onMenuClick(event) {
            if(this.isHorizontal() && DomHandler.hasClass(event.target,'layout-menu-container')) {
                EventBus.emit('reset-active-index');
                this.menuClick = false;
                this.menuActive = false;
            }
            this.menuClick = true;
        },

        onMenuButtonClick(event) {
            this.menuClick = true;
            this.topbarUserMenuActive = false;

            if (this.isMobile()) {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },

        onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items && this.isSlim()) {
                this.menuActive = false;
            }
        },

		onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },

        onConfigClick() {
            this.configClick = true;
        },

        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },

        hideOverlayMenu() {
            this.staticMenuMobileActive = false;
            this.unblockBodyScroll();
        },

        onChangeLayoutMode(mode) {
            this.layoutMode = mode;
            if(mode === 'sidebar') { 
                if(this.sidebarStatic) {
                    this.sidebarActive = true;
                }
            }
            else {
                this.sidebarActive = false;

                if(this.topbarTheme !== this.menuTheme) {
                    this.$emit('menu-theme', this.topbarTheme);
                }
            }   
        },

        onTopbarThemeChange(theme) {
            this.$emit('topbar-theme', theme);
        },

        onMenuThemeChange(theme) {
            this.$emit('menu-theme', theme);
        },

        changeComponentTheme(theme) {
            this.$emit('change-component-theme', theme);
        },

        changeColorScheme(scheme) {
            this.colorScheme = scheme;
            this.$emit('change-color-scheme', scheme);
            this.$appState.colorScheme = scheme;
        },

        onSidebarMouseOver() {
            this.sidebarActive = !this.isMobile();
        },

        onSidebarMouseLeave() {
            this.sidebarActive = false;
        },

        onTopbarUserMenuClick() {
            this.topbarUserMenuActive = !this.topbarUserMenuActive;
            this.topbarUserMenuClick = true;
        },

        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },

        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                    'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },

        isSlim() {
            return this.layoutMode === "slim";
        },
        
        isHorizontal() {
            return this.layoutMode === "horizontal";
        },

        isDesktop() {
            return window.innerWidth > 991;
        },

        isMobile() {
            return window.innerWidth <= 991;
        }
    },
};
</script>

<style lang="scss">
</style>
